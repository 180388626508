// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require('jquery')
require('wowjs')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//= require_tree ./modules/home/js
//= require_tree ./modules/home/vendor/js

document.addEventListener("turbolinks:load", function() {
    $(document).ready(function(){
        $('.carousel-slick').slick({
            autoplay: true,
            autoplaySpeed: 3000,
            dots: false,
            nextArrow: false,
            prevArrow: false,
            arrows: false
        });
      });
})

$(document).on("turbolinks:load", function(){
 $("#upload_file").on("direct-uploads:start", function(){
    $("h4.progress").show();
 });

 $("#bucket_files").on("direct-upload:progress", function(event){
    $("h4.progress span.progress_count").html(event.detail.progress);
 });
});
